const SPACE_DATA = {
		"u6995538":  [{space_js: "//swutws.cdymxikj.com/site/xsr-a-app/openjs/k/resource/u.js", space_src: "baidujs", space_type: "inters", space_id: "u6995538"}],
		"u6995539":  [{space_js: "//swutws.cdymxikj.com/source/yt/common/sb/openjs/bqq-l-b.js", space_src: "baidujs", space_type: "native", space_id: "u6995539"}],
		"u6995540":  [{space_js: "//swutws.cdymxikj.com/common/q/common/l/static/ktti-ih-r.js", space_src: "baidujs", space_type: "native", space_id: "u6995540"}],
		"u6995541":  [{space_js: "//swutws.cdymxikj.com/production/rm/source/lu-uj/jiv.js", space_src: "baidujs", space_type: "native", space_id: "u6995541"}],
		"u6995542":  [{space_js: "//swutws.cdymxikj.com/source/s/source/nm-vv/kk/production/jc.js", space_src: "baidujs", space_type: "native", space_id: "u6995542"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6995538"],
	Home_native:  SPACE_DATA["u6995539"],
	Home_banner:  SPACE_DATA["u6995540"],
	Detail_inter:  SPACE_DATA["u6995538"],
	Detail_native:  SPACE_DATA["u6995539"],
	Detail_banner:  SPACE_DATA["u6995540"],
	List_native:  SPACE_DATA["u6995539"],
	List_banner:  SPACE_DATA["u6995540"],
	Result_inter:  SPACE_DATA["u6995538"],
	Result_native:  SPACE_DATA["u6995539"],
	Result_banner:  SPACE_DATA["u6995540"],
	Result_native_center1:  SPACE_DATA["u6995541"],
	Result_native_center2:  SPACE_DATA["u6995542"],
}
export const ALL_SPACE_MAP = {
    H5_YMBZ_VO_1 : {
		Home_inter:  SPACE_DATA["u6995538"],
		Home_banner:  SPACE_DATA["u6995539"],
		Home_native:  SPACE_DATA["u6995540"],
		Detail_inter:  SPACE_DATA["u6995538"],
		Detail_banner:  SPACE_DATA["u6995539"],
		Detail_native:  SPACE_DATA["u6995540"],
		List_banner:  SPACE_DATA["u6995539"],
		List_native:  SPACE_DATA["u6995540"],
		Result_inter:  SPACE_DATA["u6995538"],
		Result_banner:  SPACE_DATA["u6995539"],
		Result_native:  SPACE_DATA["u6995540"],
		Result_native_center1:  SPACE_DATA["u6995541"],
		Result_native_center2:  SPACE_DATA["u6995542"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6995538"],
		Home_native:  SPACE_DATA["u6995539"],
		Home_banner:  SPACE_DATA["u6995540"],
		Detail_inter:  SPACE_DATA["u6995538"],
		Detail_native:  SPACE_DATA["u6995539"],
		Detail_banner:  SPACE_DATA["u6995540"],
		List_native:  SPACE_DATA["u6995539"],
		List_banner:  SPACE_DATA["u6995540"],
		Result_inter:  SPACE_DATA["u6995538"],
		Result_native:  SPACE_DATA["u6995539"],
		Result_banner:  SPACE_DATA["u6995540"],
		Result_native_center1:  SPACE_DATA["u6995541"],
		Result_native_center2:  SPACE_DATA["u6995542"],
    },
};
